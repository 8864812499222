<template lang="pug">
.brands-and-sku
	Menu(title="Brands and SKU")

	.py-20.container
		template(v-if="isMounted")
			.main-content
				h2.mb-20.title(v-if="!currentSKUId") Бренды

				h2.mb-20.flex.items-center.title(v-else)
					RouterLink.mr-4(:to="{ name: 'Nestle.BrandsAndSKU.Brand' }")
						| Бренды	
					span > SKU бренда {{ brandName }}


				RouterView(:request-params="pageRequestParams" :need-update="needUpdateResult" @brand="brandName = $event")

		.empty-filter(v-else) Пожалуйста, выберите значения фильтра
</template>

<script>
import Menu from '@/components/Menu/Menu.vue'

export default {
	name: 'BrandsAndSKU',
	components: {
		Menu,
	},

	props: {
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
    subBrands: {
      type: String,
      default: '',
    },
		regions: {
			type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},

	},

	data() {
		return {
			isMounted: false,
			brandName: '',
		}
	},

	computed: {
		currentSKUId() {
			return this.$route.params.id;
		},

		pageRequestParams() {
			return {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
        subBrands: this.subBrands,
				regions: this.regions,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
			}
		},
	},

	watch: {
		needUpdateResult() {
			this.isMounted = true;
		},
	},
}
</script>

<style lang="scss" scoped>
.title {
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	color: color(black);
}

.empty-filter {
	font-size: 16px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
</style>
